import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import { useToggle } from 'hooks/useToggle';
import { addRegion, getRegions, getTransferConfig, selectIsLoading } from 'store/transfer-config';
import CountrySelect from 'components/CountrySelect';

export const CreateRegionModal = () => {
  const { active, activate, deactivate } = useToggle();
  const [country, setCountry] = useState();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);

  const handleSubmit = useCallback(async () => {
    await dispatch(addRegion(country));
    deactivate();
    dispatch(getRegions());
    dispatch(getTransferConfig());
  }, [dispatch, country, deactivate]);

  return (
    <>
      <Button type="primary" onClick={activate}>
        Add region
      </Button>
      <Modal title="Add region" open={active} onOk={handleSubmit} confirmLoading={isLoading} onCancel={deactivate}>
        <CountrySelect value={country} onChange={setCountry} />
      </Modal>
    </>
  );
};

export default CreateRegionModal;
