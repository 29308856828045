import { memo, useContext } from 'react';
import { Checkbox } from 'antd';
import { Context } from './GridRow';

const StatusCheckbox = ({ value }) => {
  const { statuses } = useContext(Context);

  return <Checkbox key={value} value={value} checked={statuses.includes(value)} />;
};

export default memo(StatusCheckbox);
