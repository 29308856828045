import { useCallback, useState } from 'react';
import { Checkbox } from 'antd';

const StatusCheckboxGroup = ({ statuses, value, onChange }) => {
  const [val, setVal] = useState(value);

  const onCheckboxChange = useCallback(
    (e) => {
      const { checked, value } = e.target;

      const idx = statuses.indexOf(value);
      const chunk = statuses.slice(idx);
      const newVal = checked ? chunk : val.filter((status) => !chunk.includes(status));

      setVal(newVal);
      onChange(newVal);
    },
    [val, onChange],
  );

  return (
    <Checkbox.Group value={val}>
      {statuses.map((status) => (
        <Checkbox key={status} onChange={onCheckboxChange} value={status} style={{ width: '100%', marginLeft: 0 }}>
          {status}
        </Checkbox>
      ))}
    </Checkbox.Group>
  );
};

export default StatusCheckboxGroup;
