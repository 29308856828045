import { Divider, Tabs } from 'antd';
import List from './fragments/List';
import Grid from './fragments/Grid';
import RegionsModal from './fragments/RegionsModal';

const tabs = [
  {
    key: '1',
    label: 'List',
    children: <List />,
  },
  {
    key: '2',
    label: 'Grid',
    children: <Grid />,
  },
];

const AccountRegions = () => (
  <>
    <RegionsModal />
    <Divider />
    <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} />
  </>
);

export default AccountRegions;
