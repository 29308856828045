import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useToggle } from 'hooks/useToggle';
import { ACCOUNT_REGIONS } from 'config/accounts';
import { selectIsLoading, updateTransferConfig } from 'store/transfer-config';
import CountrySelect from 'components/CountrySelect';
import AccountSelect from '../../components/AccountSelect';
import StatusCheckboxGroup from './StatusCheckboxGroup';

const UpdateConfigModal = ({ config, onClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { active, activate, deactivate } = useToggle();
  const isLoading = useSelector(selectIsLoading);

  const currentStatuses = ACCOUNT_REGIONS[config.type].statuses;

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await dispatch(
          updateTransferConfig(config._id, {
            ...config,
            statuses: values.statuses,
          }),
        );
        deactivate();
        onClose?.();
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, deactivate, onClose, config],
  );

  return (
    <>
      <Button type="primary" icon={<EditOutlined />} onClick={activate} />
      <Modal
        width={500}
        title="Update config"
        open={active}
        onOk={form.submit}
        confirmLoading={isLoading}
        okText="Save"
        onCancel={deactivate}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={config}>
          <Form.Item label="Account" name="type">
            <AccountSelect disabled />
          </Form.Item>
          <Row gutter={10} style={{ marginBottom: -24 }}>
            <Col span={12}>
              <Form.Item label="Country from" name="countryFrom">
                <CountrySelect disabled />
              </Form.Item>
              <Form.Item label="Statuses" name="statuses">
                <StatusCheckboxGroup statuses={currentStatuses} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Country to" name="countryTo">
                <CountrySelect disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateConfigModal;
